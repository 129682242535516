import {useLoaderData, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import "../../Assets/Css/global.css";
import '../../Assets/Css/Pages/ProductsList/products_list.css'
import '../../Assets/Css/Pages/Offer/direct_offer.css'
import OfferInterface from "./OfferInterface";
// @ts-ignore
import imageNotFound from "../../Assets/Images/image-not-found.png";
// @ts-ignore
import logo from "../../Assets/Images/logo.png";
// @ts-ignore
import offerLogos from "../../Assets/Images/offer_logos.png";
import OfferFormInterface from "./OfferFormInterface";
import Product from "./Components/Product";
import OfferHeader from "./Components/Header";
import GenerateButton from "./Components/GenerateButton";

const defaultData: OfferInterface = {
    id: 0,
    createdAt: '',
    name: '',
    authorName: '',
    dateEnd: '',
    dateStart: '',
    currency: '',
    comment: '',
    contractor: '',
    minimalOrderValue: 0,
    onlyAboveMinimum: false,
    offerItems: []
}

function DirectOffer(){
    const loader: any               = useLoaderData();
    const {token}                   = useParams();
    const [offer]                   = useState<OfferInterface>(loader ?? defaultData);
    const [formState, setFormState] = useState<OfferFormInterface>({token: token ?? '', offerItems: {}});
    const [total, setTotal]         = useState<number>(0);

    useEffect(() => {
        let calcTotal = 0;
        Object.entries(formState.offerItems).forEach(([product_id, quantity]) => {
            const item = offer.offerItems.find(item => item.ESProductData.id === parseInt(product_id));

            if (item){
                calcTotal += item.discountPrice * quantity;
            }
        });

        setTotal(calcTotal);

    }, [formState]);

    return(
        <>
            <div className={'direct-offer'} >
                <OfferHeader offer={offer} total={total} />

                <div className={'products-list'} >
                    <div className={'list'} >
                        <div className={'products'} >
                            <div className="matrix">
                                {offer.offerItems.map((product: object) => {
                                    return <Product data={product} offer={offer} setFormState={setFormState} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <GenerateButton {...{offer, formState, total}} />
            </div>
        </>
    );
}



export {DirectOffer};