import React, {useEffect, useState} from "react";
import {GET} from "../../../../../Hooks/RequestV2";
import {FilterComponent} from "./FilterComponent";
import {useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

interface ReceiverInterface {
    id: number,
    name: string
}

function Receiver(): React.ReactElement {
    const form = useFormContext();
    const {t} = useTranslation();
    const [receivers, setReceivers] = useState<ReceiverInterface[]>([]);

    useEffect(() => {
        GET('/user/receivers')
            .then((response) => response.json())
            .then((json) => setReceivers(json));
    }, []);

    return (
        <FilterComponent title={t('user-page.invoice.filters.receiver')} className={'receiver'} >
            {receivers.length > 0 ?

                <select {...form.register('receiver')} >
                    <option value="" >---  {t('user-page.payment.all-receivers')}</option>
                    {receivers.map((receiver: ReceiverInterface, key: number) => <option key={key} value={receiver.id} >{receiver.name}</option>)}
                </select>

                : ''}
        </FilterComponent>
    );
}

export {Receiver};