import OfferInterface from "../OfferInterface";
// @ts-ignore
import logo from "../../../Assets/Images/logo.png";
// @ts-ignore
import offerLogos from "../../../Assets/Images/offer_logos.png";
import {priceBuilder} from "../../../Hooks/Price";
import React from "react";

export default function OfferHeader({offer, total}: {offer: OfferInterface, total: number}) {
    return (
        <div className={'header'}>
            <div>
                <img src={logo} className={'logo'}/>
                <img src={offerLogos} className={'other-logos'}/>
            </div>

            <div className={'title'} >Oferta nr {offer.id} z dnia {offer.createdAt}</div>

            <div className={'base-desc'}>
                <div className={'attr'}>
                    <span>Nazwa oferty</span>
                    <span>{offer.name}</span>
                </div>
                <div className={'attr'}>
                    <span>Przygotował</span>
                    <span>{offer.authorName}</span>
                </div>
                <div className={'attr'}>
                    <span>Okres obowiązywania</span>
                    <span>{offer.dateStart ? 'Od '+offer.dateStart+' ' : null}do {offer.dateEnd}</span>
                </div>
                <div className={'attr'}>
                    <span>Waluta oferty</span>
                    <span>{offer.currency}</span>
                </div>
                <div className={'attr'}>
                    <span>Wartość zamówienia [netto]</span>
                    <span>{priceBuilder(total, offer.currency)}</span>
                </div>
                {offer.contractor ?

                    <div className={'attr'}>
                        <span>Nazwa kontrahenta</span>
                        <span>{offer.contractor}</span>
                    </div>

                    : <div className={'attr empty'}>&nbsp;</div>}
                {offer.comment ?

                    <div className={'attr full'}>
                        <span>Komentarz do oferty</span>
                        <span>{offer.comment}</span>
                    </div>

                    : null}
            </div>
        </div>
    );
}