import {Link, useLoaderData} from "react-router-dom";
import UserAccount from "../../../Components/UserAccount";
import {Table} from "../../../Components/Table";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";

function WZs(){
    const loader = useLoaderData();
    const [wzs, setWzs] = useState(loader);
    const {t}               = useTranslation();
    const {user} = useContext(UserContext);

    const columns = [
        {
            header: t('user-page.wzs.wz-number'),
            accessorKey: 'wzNumber'
        },
        {
            header: t('user-page.wzs.options'),
            accessorKey: 'id',
            cell: ({getValue}) => {
                return(
                    <>
                        <Link to={'/user/wz/'+getValue()} >
                            <button className="btn teal" >
                                <i className="fas fa-pallet" ></i> {t('user-page.wzs.details')}
                            </button>
                        </Link>
                    </>
                );
            }
        }
    ];

    const table = useReactTable({
        data: wzs,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (wzs === null){
        return (<></>);
    }

    const breadcrumb = [{name: t('user-page.wzs.title'), path: null}];

    return(
        <>
            <UserAccount title={t('user-page.wzs.title')} className="my-wzs" breadcrumb={breadcrumb} >
                <Table table={table} additionalBottom={undefined}  />
            </UserAccount>
        </>
    );
}

export default WZs;
