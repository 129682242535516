import React from "react";
import UserAccount from "../../../Components/UserAccount";
import {useTranslation} from "react-i18next";
import {POST} from "../../../Hooks/RequestV2";

function Download(): React.ReactElement {
    const {t} = useTranslation();
    const breadcrumb = [{name: t('products-list.download-xml'), path: null}];

    function getFileName(contentDisposition: any) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }

        return null;
    }

    function download(){
        POST('/user/all-product/generate-xml', {})
            .then((response) => {
                if (response.status === 200){
                    response.blob().then((blob) => {
                        const contentDisposition = response.headers.get('Content-Disposition');
                        const fileName = getFileName(contentDisposition ?? 'produkty.csv') ?? 'produkty.csv';
                        const fileNameArray = fileName?.split('.') ?? ['produkty', 'csv'];

                        const blobWithType = new Blob([blob], { type: 'application/octet-stream' });

                        const url = URL.createObjectURL(blobWithType);
                        const a = document.createElement('a');

                        a.href = url;
                        a.download = fileNameArray[0] + '.' + fileNameArray[1];

                        document.body.appendChild(a);

                        a.click();
                        window.URL.revokeObjectURL(a.href);
                        document.body.removeChild(a);
                    });
                }
            });
    }

    return(
        <UserAccount title={t('products-list.download-xml')} className="download-xml" breadcrumb={breadcrumb} >

            <div style={{textAlign: "center"}} >
                <button className={'btn'} onClick={() => download()} >{t('user-page.download-xml.button')}</button>
            </div>

        </UserAccount>
    );
}

export {Download};