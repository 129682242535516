import React from "react";
import {POST} from "../../../Hooks/RequestV2";
import GenerateButtonInterface from "./GenerateButtonInterface";
import {priceBuilder, priceWithoutCurrencyBuilder} from "../../../Hooks/Price";
import {Tooltip} from "react-tooltip";

export default function GenerateButton({offer, formState, total}: GenerateButtonInterface): React.ReactElement {
    function sendDataAndGeneratedXlsFile(): void {
        POST('/offer/link', formState)
            .then((response) => {
                if (response.ok){
                    return response.blob();
                }
            })
            .then((blob: any) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');

                let name = 'Zamówienie do oferty nr ' + offer.id;

                a.href = url;
                a.download = name + '.xls';

                document.body.appendChild(a);

                a.click();

                document.body.removeChild(a);
            });
    }

    return(
        <div className={'generated-button'} >
            <button
                className={'btn'}
                id={'generated-xls'}
                disabled={offer.onlyAboveMinimum && total < offer.minimalOrderValue}
                onClick={() => sendDataAndGeneratedXlsFile()} >Generuj zamówienie XLS</button>

            {offer.onlyAboveMinimum && total < offer.minimalOrderValue ?

                <Tooltip anchorSelect={'#generated-xls'} >
                    Zamówienie nie spełnia minimalnej wartości.
                </Tooltip>

                : null}

            {offer.minimalOrderValue > 0 ?

                <div className={'minimum'} >
                    <div>
                        <strong>Minimalna / aktualna wart. zam.:</strong>
                        <span>{priceWithoutCurrencyBuilder(offer.minimalOrderValue)} / {priceWithoutCurrencyBuilder(total)}</span>
                    </div>
                </div>

                : null}

        </div>
    );
}