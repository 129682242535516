const currencies = {'PLN':'zł', 'USD':'$', 'CZK':'Kč', 'EUR':'€', '':'(?)'};

/**
 * @param price
 * @param currency
 * @returns {string|*}
 */
function priceBuilder(price, currency){
    price = price.toFixed(2).replace('.', ',');

    if (price.split(',')[0].length > 3){
        price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    if (currency === 'USD'){
        return currencies[currency]+price;
    }

    return price+' '+currencies[currency];
}

function priceWithoutCurrencyBuilder(price){
    price = price.toFixed(2).replace('.', ',');

    if (price.split(',')[0].length > 3){
        price = price.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return price;
}

export {priceBuilder, priceWithoutCurrencyBuilder};
