import {RemoveSavedCart, TransferSavedCartToCurrent} from "../../../Requests/User";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import UserAccount from "../../../Components/UserAccount";
import {Table} from "../../../Components/Table";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "../../../Assets/Css/Pages/User/SavedCart/savedCarts.css"
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {GET} from "../../../Hooks/RequestV2";
import {SavedCartModal} from "./TransferSavedCartToCart";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {Throbber} from "../../../Components/Throbber";

function SavedCarts(){
    const loader = useLoaderData();
    const [savedCarts, setSavedCarts] = useState(loader);
    const {t}                         = useTranslation();
    const {removeSavedCartRequest, removeSavedCartResponse} = RemoveSavedCart();
    const {user}                      = useContext(UserContext);
    const [throbber, setThrobber]     = useState(false);

    useEffect(() => {
        GET('/user/saved-carts')
            .then((response) => response.json())
            .then((json) => setSavedCarts(json));
    }, [removeSavedCartResponse]);

    function remove(id){
        removeSavedCartRequest({saved_cart_id: id});
    }

    const columns = [
        {
            header: t('user-page.saved-carts.name'),
            accessorKey: 'name'
        },
        {
            header: t('user-page.saved-carts.options'),
            accessorKey: 'id',
            cell: ({getValue}) => {
                return <OptionsComponent getValue={getValue} remove={remove} setThrobber={setThrobber} />
            }
        }
    ];

    const table = useReactTable({
        data: savedCarts,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (savedCarts === null){
        return (<></>);
    }

    const breadcrumb = [{name: t('user-page.saved-carts.title'), path: null}];

    return(
        <>
            <UserAccount title={t('user-page.saved-carts.title')} className="my-saved-carts" breadcrumb={breadcrumb} >
                <Throbber visible={throbber} />
                <Table table={table} additionalBottom={undefined}  />
            </UserAccount>
        </>
    );
}

function OptionsComponent({getValue, remove, setThrobber}) {
    const {t} = useTranslation();

    return(
        <>
            <Link to={'/user/saved-cart/'+getValue()} >
                <button className="btn teal" >
                    <i className="fas fa-list" ></i> {t('user-page.saved-carts.detail')}
                </button>
            </Link>
            <SavedCartModal setThrobber={setThrobber} endpoint={'/user/saved-cart/transfer'} idItem={getValue()} />
            <button className="btn red" onClick={() => remove(getValue())} >
                <i className="fas fa-times"></i> {t('cart.remove')}
            </button>
        </>
    );
}

export default SavedCarts;
