import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../Contexts/UserContext";
import {useNavigate} from "react-router-dom";
import {GET} from "./RequestV2";
import {ChannelContext} from "../Contexts/ChannelContext";

function generateRandomHash(length: number = 16): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export default function TimerCacheAuthClock(): React.ReactElement {
    const userContext = useContext(UserContext);
    const channelContext = useContext(ChannelContext);
    const [randomToken, setRandomToken] = useState<string>(generateRandomHash());
    const navigate = useNavigate();

    useEffect(() => {
        if (!userContext || !userContext.user) {
            console.debug('User is not authenticated or context is unavailable. Timer will not start.');
            return;
        }

        console.debug('Start timer for authenticated user.');

        const timeOutId = setTimeout(() => {
            console.debug('User session expired. Logging out...');

            window.location.href = window.location.protocol+'//'+channelContext?.formLoginAddress+'/login';
        }, 3600000);

        return () => {
            console.debug('Timer cleared.');
            clearTimeout(timeOutId);
        };
    }, [userContext, randomToken]);

    return <></>;
}