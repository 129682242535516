import RequestConfig from "../Requests/RequestConfig.json";
import i18n from "i18next";
import {useCookie} from "./Cookies";

async function RequestV2(path: string, method?: string, body?: object): Promise<Response> {
    const {getCookie} = useCookie();

    let headers = {
        'Content-Language': (i18n.language || 'pl-PL'),
        'Currency': (getCookie('currency-code') || 'pln'),
        'Content-Type': 'application/json'
    };

    if (localStorage.getItem('contractor') !== null){
        headers = {...headers, ...{'contractor': localStorage.getItem('contractor')}}
    }

    return await fetch(RequestConfig.domain+path, {
        credentials: 'include',
        method: (method || 'GET'),
        body: (body ? JSON.stringify(body) : null),
        headers: headers
    });
}

function GET(path: string): Promise<Response>{
    return RequestV2(path);
}

function POST(path: string, body: object): Promise<Response>{
    return RequestV2(path, 'POST', body);
}

function PUT(path: string, body: object): Promise<Response>{
    return RequestV2(path, 'PUT', body);
}

function PATCH(path: string, body: object): Promise<Response>{
    return RequestV2(path, 'PATCH', body);
}

function DELETE(path: string, body: object): Promise<Response>{
    return RequestV2(path, 'DELETE', body);
}

export {GET, POST, PUT, PATCH, DELETE};
