import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import OfferFormInterface from "../OfferFormInterface";
import config from "../../../Requests/RequestConfig.json";
// @ts-ignore
import imageNotFound from "../../../Assets/Images/image-not-found.png";
import {Link} from "react-router-dom";
import Barcode from "react-barcode";

export default function Product({data, offer, setFormState}: any): React.ReactElement {
    // const {t}           = useTranslation();
    const imageModal    = useSimpleModalState();
    const descModal     = useSimpleModalState();
    const inputRef      = useRef<HTMLInputElement | null>(null);
    const [quantityPackage, setQuantityPackage] = useState<number>(0);

    useEffect(() => {
        if (inputRef.current && quantityPackage !== 0){
            inputRef.current.value = calculatedPackage();
        }
    }, [quantityPackage]);

    function displayPrice() {
        return (
            <div className="prices" >
                <div className="price-title" >
                    {offer.options.normalPrice === true && data.discount > 0 ?

                        <div className="old-price" >{data.price.toFixed(2)} netto</div>

                        : null}

                    {offer.options.discount === true ?
                        <div className="price-discount" >
                            Rabat: {data.discount}%
                        </div>
                        : null }
                </div>

                {offer.options.discountPrice === true ?

                    <div className="price-wrapper" >
                        <div className="netto" >{data.discountPrice.toFixed(2)} netto</div>
                    </div>

                    : null}

            </div>
        );
    }

    function displayQuantity() {
        return (
            <div className="quantity" >
                <span>Dostępność:</span>
                <span>{data.disposition} {data.ESProductData.unit.toUpperCase()}</span>
            </div>
        );
    }

    function calculatedPackage(): string {
        let quantity: number = 0;

        if (offer.calculatedType === 'package'){
            quantity = Number(Number(quantityPackage * data.ESProductData.quantityInPackage).toFixed(0));
        } else if (offer.calculatedType === 'pieces') {
            quantity = quantityPackage;
        } else {
            if (!offer.productsExceptionsIds.includes(data.ESProductData.id)){
                quantity = quantityPackage;
            } else {
                quantity = Number(Number(quantityPackage * data.ESProductData.quantityInPackage).toFixed(0));
            }
        }

        setFormState((prev: OfferFormInterface) => {
            const productId = data.ESProductData.id;

            // Sprawdzamy, czy produkt już istnieje w obiekcie offerItems
            const existingQuantity = prev.offerItems[productId];

            if (existingQuantity !== undefined) {
                if (quantity === 0) {

                    // Tworzymy nowy obiekt bez danego product_id
                    const { [productId]: _, ...updatedItems } = prev.offerItems;

                    return {
                        ...prev,
                        offerItems: updatedItems, // Zaktualizowany obiekt bez produktu
                    };
                } else {

                    // Aktualizujemy ilość dla istniejącego product_id
                    return {
                        ...prev,
                        offerItems: {
                            ...prev.offerItems,
                            [productId]: quantity, // Zaktualizowana ilość
                        },
                    };
                }
            } else {
                if (quantity > 0) {

                    // Dodajemy nowy produkt do obiektu offerItems
                    return {
                        ...prev,
                        offerItems: {
                            ...prev.offerItems,
                            [productId]: quantity, // Nowy produkt z ilością
                        },
                    };
                } else {
                    return prev; // Jeśli ilość jest 0 i element nie istnieje, nie robimy nic
                }
            }
        });

        return quantity+' '+data.ESProductData.unit;
    }

    let name: string = '';

    if (data.ESProductData.name[4].length > 60){
        name = data.ESProductData.name[4].substring(0, 70) + ' ...';
    } else {
        name = data.ESProductData.name[4];
    }

    return (
        <section className="product" >
            {offer.options.thumb === true ?
                <div
                    className="thumb"
                    style={{backgroundImage: 'url('+(data.ESProductData.thumb ? config.domain+'/assets/img/thumbnail/'+data.ESProductData.thumb : imageNotFound+')')}}
                    onClick={() => {

                        if (data.ESProductData.thumb){
                            imageModal.toggleVisibleModal();
                        }
                    }} >

                    {data.ESProductData.thumb ? <i className="fas fa-search-plus more" /> : null}
                </div>
                : null}

            <SimpleModal visibility={imageModal.visibility} hidden={imageModal.toggleVisibleModal} className={'product-thumb-modal'} wait={false} >
                <img src={(data.ESProductData.thumb ? config.domain+'/assets/img/'+data.ESProductData.thumb : imageNotFound+')')} className={'thumb-img'} />
            </SimpleModal>

            <SimpleModal visibility={descModal.visibility} hidden={descModal.toggleVisibleModal} className={'description-modal'} wait={false} >
                <div dangerouslySetInnerHTML={{__html: data.ESProductData.description[4]}} ></div>
            </SimpleModal>

            {offer.options.productName === true ?

                // <Link to={'/product/'+data.ESProductData.slug} >
                    <h2>{name}</h2>
                // </Link>

                : null}


            {displayPrice()}
            <div className="attributes" >
                {offer.options.index === true ?

                    <div className="index" >
                        <span>Index ET:</span>
                        <span>{data.ESProductData.index_hurt}</span>
                    </div>

                    : null }

                {offer.options.ean === true ?
                    <div className="ean" >
                        <span>EAN:</span>
                        <span>{data.ESProductData.default_ean !== null ? data.ESProductData.default_ean : '-'}</span>
                    </div>
                    : null}

                {offer.options.offerQuantity === true ?

                    displayQuantity()

                    : null}


                {offer.options.package === true ?

                    <div className="package" >
                        <span>W opakowaniu:</span>
                        <span>{data.ESProductData.quantityInPackage}</span>
                    </div>

                    : null}


                {offer.options.producer === true ?

                    <div className="package" >
                        <span>Producent:</span>
                        <span>{data.ESProductData.producer.name}</span>
                    </div>

                    : null}

            </div>
            <div className="attributes" >
                {offer.options.moqHurt === true ?

                    <div className="index" >
                        <span>MOQ:</span>
                        <span>{data.ESProductData.moq_hurt}</span>
                    </div>

                    : null}

                {offer.options.moqShop === true ?

                    <div className="index" >
                        <span>MOQ:</span>
                        <span>{data.ESProductData.moq_shop}</span>
                    </div>

                    : null}

                {offer.options.packageOffer === true ?
                    <div className="ean" >
                        <span>Pakowanie:</span>
                        <span>{data.package ?? '-'}</span>
                    </div>

                    : null}

                {offer.options.brand === true ?

                    <div className="package" >
                        <span>Brand:</span>
                        <span>{data.ESProductData.brand.name}</span>
                    </div>

                    : null}

                {offer.options.license === true ?

                    <div className="package" >
                        <span>Licencja:</span>
                        <span>{data.ESProductData.license?.name}</span>
                    </div>

                    : null}

                {offer.options.desc === true ?

                    <div>
                        {data.ESProductData.description[4] ?

                            <button onClick={() => descModal.toggleVisibleModal()} >
                                <i className={'fas fa-search-plus'} /> Opis
                            </button>

                            : 'Brak opisu produktu'}

                    </div>

                    : null}

            </div>

            {offer.options.ean === true && data.ESProductData.default_ean !== null ?
                <div className={'barcode'} >
                    <Barcode value={data.ESProductData.default_ean} displayValue={false} height={50} />
                </div>
                : null}


            {offer.options.remarks === true && data.comment ?

                <div className={'comment'} >{data.comments}</div>

                : null}


            <div className="add-to-cart" >
                <div className="field" >
                    <span>Wpisz ilość: </span>

                    <input
                        type="number"
                        name="quantity"
                        step={1}
                        min="0"
                        // ref={inputRef}
                        onWheel={(event) => {
                            event.currentTarget.blur();
                            event.preventDefault();
                            return false;
                        }}
                        onWheelCapture={(event) => {
                            event.currentTarget.blur();
                            event.preventDefault();
                            return false;
                        }}
                        onKeyDown={(event) => {
                            const inputs = document.querySelectorAll('.list .product input[name="quantity"]') as NodeListOf<HTMLInputElement>;
                            const currentIndex = Array.from(inputs).indexOf(event.currentTarget);

                            if (event.key === 'ArrowDown') {
                                if (currentIndex >= 0 && currentIndex < inputs.length - 1) {
                                    inputs[currentIndex + 1].focus();
                                }

                                event.preventDefault();
                            }

                            if (event.key === 'ArrowUp'){
                                if (currentIndex !== 0) {
                                    inputs[currentIndex - 1].focus();
                                }

                                event.preventDefault();
                            }
                        }}
                        onInput={(e: any) => {
                            setQuantityPackage(e.currentTarget.value);
                        }} />
                </div>
                <div className="field" >
                    <input
                        type="text"
                        name="quantity-package"
                        disabled={true}
                        // value={calculatedPackage()}
                        ref={inputRef}
                    />
                </div>
            </div>

        </section>
    );
}