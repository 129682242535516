import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route, ScrollRestoration, useLocation, useMatches, useNavigate,
    useNavigation, useSearchParams
} from "react-router-dom";
import React, {ReactElement, useContext, useEffect, useReducer, useState} from "react";
import {GET, POST} from "./Hooks/RequestV2";
import {buildLoaderRequest} from "./Pages/ProductsList/FiltersDataHelper";
import {Throbber} from "./Components/Throbber";

import Header from "./Layouts/Header/Header";
import Footer from "./Layouts/Footer/Footer";

import Home from "./Pages/Home/Home";
import ProductsList from "./Pages/ProductsList/ProductsList";
import Cart from "./Pages/Cart/Cart";
import Product from "./Pages/Product/Product";
import MyAccount from "./Pages/User/MyAccount/MyAccount";
import Orders from "./Pages/User/Orders/Orders";
import Order from "./Pages/User/Orders/Order";
import Invoices from "./Pages/User/Invoices/Invoices";
import Invoice from "./Pages/User/Invoices/Invoice";
import WZs from "./Pages/User/WZ/WZs";
import WZ from "./Pages/User/WZ/WZ";
import SavedCarts from "./Pages/User/SavedCarts/SavedCarts";
import SavedCart from "./Pages/User/SavedCarts/SavedCart";
import Payment from "./Pages/User/Payment/payment";
import {CartType} from "./Types/CartType";
import {CartContext} from "./Contexts/CartContext";
import {UserType} from "./Types/UserType";
import {UserContext} from "./Contexts/UserContext";
import {HeaderScroll} from "./Layouts/Header/HeaderScroll";
import {ForceReloadContext} from "./Contexts/ForceReloadContext";
import {useForceReload} from "./Hooks/ForceReload";
import {DirectOffer} from "./Pages/Offer/DirectOffer";
import {RedirectNotification} from "./Components/Notification/Redirect";
import {ChannelContext} from "./Contexts/ChannelContext";
import {ImportOrder} from "./Pages/User/ImportOrder/ImportOrder";
import {ProductFilters} from "./Pages/ProductFilters";
import {ChangeCurrencyReloadCart} from "./Components/Notification/ChangeCurrencyReloadCart";
import {PageLogin} from "./Pages/LogIn/Form";
import {MiniCart} from "./Components/MiniCart";
import {useRedirectToLoginForm} from "./Hooks/RedirectToLoginForm";
import {Download} from "./Pages/User/DownloadXML/download";
import TimerCacheAuthClock from "./Hooks/CacheAuthAppClock";


function Root(): ReactElement{
    const navigation = useNavigation();
    const navigate = useNavigate();
    const channel = useContext(ChannelContext);

    const [cart, setCart] = useState<CartType|null>(null);
    const [user, setUser] = useState<UserType|null|false>(null);
    const [forceReloadView, setForceReloadView] = useState<string|null>(null);

    useRedirectToLoginForm();

    let cartTimeout: string | number | NodeJS.Timeout | null | undefined = null;

    useEffect(() => {
        GET('/user').then((response) => {

            if (response.ok){
                response.json().then((json: UserType) => {
                    setUser(json);
                    GET('/user/cart')
                        .then((response) => response.json())
                        .then((json) => setCart(json));
                });
            } else {
                if (response.status === 301){
                    response.json().then((json: any) => {
                        window.location.href = 'https://'+json.host+'/?redirect='+channel?.name;
                    });
                }

                setUser(false);
            }
        });

        const bcChangeContractorStorageId = new BroadcastChannel('change-contractor-local-storage');
        const bcReloadCart = new BroadcastChannel('reload-cart');
        const bcLogout = new BroadcastChannel('logout');
        const bcLogin = new BroadcastChannel('login');

        bcChangeContractorStorageId.onmessage = (event) => {
            GET('/user').then((response) => {
                if (response.ok) {
                    response.json().then((json: UserType) => {
                        setUser(json);
                    });
                }
            });
        };

        bcReloadCart.onmessage = (event) => {
            GET('/user/cart')
                .then((response) => response.json())
                .then((json) => setCart(json));
        };

        bcLogout.onmessage = (event) => {
            setUser(false);
            window.location.href = window.location.protocol+'//'+channel?.formLoginAddress+'/login';
        };

        bcLogin.onmessage = (event) => {
            navigate('/');
        };
    }, []);

    if (user !== null){
        return (
            <>
                <ForceReloadContext.Provider value={{forceReloadView, setForceReloadView}}>
                    <CartContext.Provider value={{cart, setCart}} >
                        <UserContext.Provider value={{user, setUser}} >
                            <MiniCart />
                            <RedirectNotification />
                            <ChangeCurrencyReloadCart />
                            <TimerCacheAuthClock />

                            <Header/>
                            <main>
                                <ScrollRestoration
                                    getKey={(location, matches) => {
                                        return location.pathname;
                                    }}
                                />
                                <Throbber visible={navigation.state === 'loading'} />
                                <div className={'main-content'+(navigation.state === 'loading' ? ' loading': '')} >
                                    {/*<React.StrictMode>*/}
                                        <Outlet />
                                    {/*</React.StrictMode>*/}
                                </div>
                            </main>
                            <Footer/>
                        </UserContext.Provider>
                    </CartContext.Provider>
                </ForceReloadContext.Provider>
            </>
        );
    }

    return <></>;
}

function Routing(){


    return createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<Root />} >
                    <Route index path="/" element={<Home/>} />
                    <Route path="/cart" element={<Cart />} loader={async () => {
                        return await GET('/user/cart');
                    }} />

                    <Route path="/filter/:menuitem" element={<ProductFilters />} loader={async () => {
                        return await GET('/filters/menu');
                    }} />
                    <Route path="/filter/:menuitem/:letter" element={<ProductFilters />} loader={async () => {
                        return await GET('/filters/menu');
                    }} />

                    <Route path="/products-list/:filter"  element={<ProductsList />} loader={async (LoaderArgs) => {
                        return await POST(
                            '/filters/products-list',
                            buildLoaderRequest(LoaderArgs.params, LoaderArgs.request.url)
                        );
                    }} />
                    <Route path="/products-list/:filter/:id" element={<ProductsList />} loader={async (LoaderArgs) => {

                        return await POST(
                            '/filters/products-list',
                            buildLoaderRequest(LoaderArgs.params, LoaderArgs.request.url)
                        );
                    }} />
                    <Route path="/product/:slug" element={<Product />} loader={async (LoaderArgs) => {

                        return await GET('/product/'+LoaderArgs.params.slug);
                    }} />

                    <Route path="/user/my-account" element={<MyAccount />} loader={async (LoaderArgs) => {
                        return await GET('/user')
                    }}  />
                    <Route path="/user/orders" element={<Orders />} loader={async (LoaderArgs) => {
                        return await GET('/user')
                    }}  />
                    <Route path="/user/order/:type/:id" element={<Order />} loader={async (LoaderArgs) => {
                        return await GET('/user/order/?number='+(LoaderArgs.params.id)?.replace('-', '/')+'&type='+LoaderArgs.params.type)
                    }} />
                    <Route path="/user/invoices" element={<Invoices />} loader={async (LoaderArgs) => {
                        return await GET('/user')
                    }}  />
                    <Route path="/user/invoice/:id" element={<Invoice />} loader={async (LoaderArgs) => {
                        return await GET('/user/invoice/'+LoaderArgs.params.id);
                    }} />
                    <Route path="/user/wz" element={<WZs />} loader={async () => {
                        return await GET('/user/wzs');
                    }} />
                    <Route path="/user/wz/:id" element={<WZ />} loader={async (LoaderArgs) => {
                        return await GET('/user/wz/'+LoaderArgs.params.id);
                    }} />
                    <Route path="/user/saved-carts" element={<SavedCarts />} loader={async () => {
                        return await GET('/user/saved-carts')
                    }} />
                    <Route path="/user/saved-cart/:id" element={<SavedCart />} loader={async (LoaderArgs) => {
                        return await GET('/user/saved-cart/'+LoaderArgs.params.id)
                    }} />

                    <Route path="/user/load-order" element={<ImportOrder />} loader={async (LoaderArgs) => {
                        return await GET('/user')
                    }}  />

                    <Route path="/user/payments" element={<Payment />} loader={async () => {
                        return await GET('/user/payments');
                    }} />

                    <Route path="/user/download-xml" element={<Download />} loader={async (LoaderArgs) => {
                        return await GET('/user')
                    }}/>
                </Route>
                <Route path="/login" element={<PageLogin />} />
                <Route path="/offer/:token" element={<DirectOffer />} loader={async (LoaderArgs) => {
                    return await GET('/offer/'+LoaderArgs.params.token);
                }} />
            </>
        )
    );
}

export {Routing, Root};
