import {Delete, Get, Post} from "../Hooks/Request";
import {useEffect, useState} from "react";
import config from "./RequestConfig.json";

function GetAuthUser(){
    const {response, setSend, httpStatusCode} = Get('/user');

    return {authUser: response, authUserRequest: setSend, authUserHttpCode: httpStatusCode};
}

function User(){
    const {response, setSend, httpStatusCode} = Get('/user');

    return {loggedUser: response, sendRequestForUser: setSend, userHttpStatusCode: httpStatusCode};
}

function ChangeUserData() {
    const {response, setBody, httpStatusCode} = Post('/user/change-data');

    return {changeUserData: response, setChangeUserData: setBody, changeUserDataHttpStatusCode: httpStatusCode}
}

function GetInvoices(){
    const {response, setSend, httpStatusCode} = Get('/user/invoices');

    return {invoices: response, invoicesRequest: setSend}
}

function GetInvoice(id){
    const {response, setSend, httpStatusCode} = Get('/user/invoice/'+id);

    return {invoice: response, invoiceRequest: setSend}
}

function GetOrders(){
    const {response, setSend, httpStatusCode} = Get('/user/orders');

    return {orders: response, ordersRequest: setSend}
}

function GetOrder(type, number, httpStatusCode){
    const {response, setSend} = Get('/user/order/?number='+number+'&type='+type);

    return {order: response, orderRequest: setSend}
}

function GetWzs(){
    const {response, setSend, httpStatusCode} = Get('/user/wzs');

    return {wzs: response, wzsRequest: setSend}
}

function GetWz(id){
    const {response, setSend, httpStatusCode} = Get('/user/wz/'+id);

    return {wz: response, wzRequest: setSend}
}

function GetSavedCarts(){
    const {response, setSend, httpStatusCode} = Get('/user/saved-carts');

    return {savedCarts: response, savedCartsRequest: setSend}
}

function GetSavedCart(id){
    const {response, setSend, httpStatusCode} = Get('/user/saved-cart/'+id);

    return {savedCart: response, savedCartRequest: setSend}
}

function TransferSavedCartToCurrent(){
    const {response, setBody, httpStatusCode} = Post('/user/saved-cart/transfer');

    return {response, transferSavedCart: setBody};
}

function RemoveSavedCart() {
    const {response, setBody, httpStatusCode} = Delete('/user/saved-cart/remove');

    return {removeSavedCartResponse: response, removeSavedCartRequest: setBody};
}

function GetPayments(){
    const {response, setSend, httpStatusCode} = Get('/user/payments');

    return {payments: response, paymentsRequest: setSend};
}

function GetPaymentsForReceivers(){
    const {response, setBody, httpStatusCode} = Post('/user/payments/receiver');

    return {receiverPayments: response, setReceiver: setBody};
}

function LogOut(){
    const {response, setSend, httpStatusCode} = Get('/user/logout');

    useEffect(() => {

        if (httpStatusCode !== 0){
            const   broadcastChannel = new BroadcastChannel('logout');
                    broadcastChannel.postMessage('logout');
        }

    }, [response]);

    return {response, setSend};
}

export {
    GetAuthUser,
    LogOut,
    User,
    ChangeUserData,
    GetInvoices,
    GetInvoice,
    GetOrders,
    GetOrder,
    GetWzs,
    GetWz,
    GetSavedCarts,
    GetSavedCart,
    GetPayments,
    GetPaymentsForReceivers,
    TransferSavedCartToCurrent,
    RemoveSavedCart
};
